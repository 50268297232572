import { useRouter } from 'next/router';

export function useRouterHash() {
  const { asPath } = useRouter();
  const components = asPath.split('#');

  if (components.length < 2) {
    return null;
  }

  return components[1];
}
