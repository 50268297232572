import { useTranslation } from 'next-i18next';

import { PageItemPosition, PageItemTravel } from 'data/Page';

const POSITION_ORIGIN: PageItemPosition = {
  longitude: 14.213187,
  latitude: 50.780688,
};

interface SidebarTravelProps {
  position: PageItemPosition;
  travel: PageItemTravel;
}

function SidebarTravel({ travel, position }: SidebarTravelProps) {
  const { t } = useTranslation('common');
  return (
    <a
      href={`https://www.google.com/maps/dir/${POSITION_ORIGIN.latitude},${POSITION_ORIGIN.longitude}/${position.latitude},${position.longitude}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`Sidebar__travel Sidebar__travel--${travel.type.toLowerCase()}`}
    >
      <div className="Sidebar__travelTime">{travel.time}</div>
      <div className="Sidebar__travelDistance">
        {travel.type === 'DRIVING' && t('DETAIL_TRAVEL_DRIVING')}
        {travel.type === 'WALKING' && t('DETAIL_TRAVEL_WALKING')}
      </div>
    </a>
  );
}

export default SidebarTravel;
