import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';

import { getWeather, WeatherResult } from 'data/Weather';

interface SidebarWeatherProps {
  latitude: number;
  longitude: number;
}

function SidebarWeather({ longitude, latitude }: SidebarWeatherProps) {
  const { t } = useTranslation('common');
  const [weather, setWeather] = useState<WeatherResult | null>(null);

  const get = useCallback(async () => {
    const data = await getWeather(latitude, longitude);
    setWeather(data);
  }, [latitude, longitude]);

  useEffect(() => {
    get();
  }, [get]);

  if (!longitude || !latitude) {
    return null;
  }

  return (
    <a
      href={`https://weather.com/cs-CZ/pocasi/dnes/l/${latitude},${longitude}`}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        'Sidebar__weather',
        `Sidebar__weather--${weather?.icon}`,
      )}
    >
      <div className="Sidebar__weatherTemperature">
        {weather?.temperature ?? '-'}&nbsp;°C
      </div>
      <div className="Sidebar__weatherCondition">
        {weather && t(`WEATHER_${weather.condition.toUpperCase()}`)}
        {!weather && t('WEATHER_LOADING')}
      </div>
    </a>
  );
}

export default SidebarWeather;
