import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useRouterHash } from 'hooks/useRouterHash';

interface RouteItem {
  slug: string;
  name: string;
  numbers: number[];
}

const ROUTES: RouteItem[] = [
  {
    slug: 'medvedi-stezka-a',
    name: 'Medvědí stezka A',
    numbers: [6, 9, 7, 1, 3, 5, 2, 4, 8],
  },
  {
    slug: 'medvedi-stezka-b',
    name: 'Medvědí stezka B',
    numbers: [11, 16, 13, 15, 17, 12, 14, 10],
  },
  {
    slug: 'zamecka-stezka-1',
    name: 'Zámecká stezka 1',
    numbers: [4, 3, 5, 8, 46],
  },
  {
    slug: 'zamecka-stezka-2',
    name: 'Zámecká stezka 2',
    numbers: [7, 10, 12, 17, 34],
  },
  {
    slug: 'zamecka-stezka-3',
    name: 'Zámecká stezka 3',
    numbers: [36, 47, 40, 42, 44, 49, 20, 9],
  },
  {
    slug: 'zamecka-stezka-4',
    name: 'Zámecká stezka 4',
    numbers: [1, 6, 35, 38, 41, 33, 27, 13],
  },
  {
    slug: 'zamecka-stezka-5',
    name: 'Zámecká stezka 5',
    numbers: [2, 37, 30, 31, 50, 25, 21, 18],
  },
  {
    slug: 'zamecka-stezka-6',
    name: 'Zámecká stezka 6',
    numbers: [14, 45, 48, 16, 22, 32, 39],
  },
  {
    slug: 'zamecka-stezka-7',
    name: 'Zámecká stezka 7',
    numbers: [11, 15, 21, 23, 24, 26, 29, 28, 19, 43],
  },
];

function ContentRouteValidator() {
  const ref = useRef<HTMLDivElement>(null);
  const hash = useRouterHash();
  const [index, setIndex] = useState(0);
  const [route, setRoute] = useState(ROUTES[0]);
  const [numbers, setNumbers] = useState<string[]>(
    ROUTES[0].numbers.map(() => ''),
  );
  const [completed, setCompleted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [empty, setEmpty] = useState(true);

  const validate = useCallback(
    () => setEmpty(numbers.filter(n => n === '').length > 0),
    [numbers],
  );

  const changeRoute = useCallback((index: number) => {
    setIndex(index);
    setRoute(ROUTES[index]);
    setNumbers(ROUTES[index].numbers.map(() => ''));
    setEmpty(true);
  }, []);

  const changeNumber = useCallback(
    (value: string, index: number) => {
      if (value === '') {
        const newNumbers = [...numbers];
        newNumbers[index] = '';
        setNumbers(newNumbers);
        return;
      }

      const number = Number(value);
      if (number < 0 || number > 99) {
        return;
      }

      const newNumbers = [...numbers];
      newNumbers[index] = String(number);
      setNumbers(newNumbers);
    },
    [numbers],
  );

  const clear = useCallback(() => {
    if (success) {
      setNumbers(route.numbers.map(() => ''));
    }

    setSuccess(false);
    setCompleted(false);
  }, [route, success]);

  const scroll = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ref]);

  const submit = useCallback(() => {
    const successNumbers = numbers.filter(
      (value, index) => Number(value) === route.numbers[index],
    );

    setSuccess(successNumbers.length === numbers.length);
    setCompleted(true);

    scroll();
  }, [numbers, route.numbers, scroll]);

  useEffect(() => {
    if (!hash) {
      return;
    }

    const index = ROUTES.findIndex(r => r.slug === hash);
    if (index < 0) {
      return;
    }

    setIndex(index);
    setRoute(ROUTES[index]);
    setNumbers(ROUTES[index].numbers.map(() => ''));
    scroll();
  }, [hash, scroll]);

  useEffect(() => validate(), [numbers, validate]);

  return (
    <>
      <hr />
      <h2 data-component="ContentRouteValidator">Ověření trasy</h2>
      <div className="ContentRouteValidator">
        <div className="ContentRouteValidator__anchor" ref={ref} />
        <div className="ContentRouteValidator__route">
          <label
            htmlFor="validator-content-route"
            className="ContentRouteValidator__label"
          >
            Trasa
          </label>
          <select
            id="validator-content-route"
            value={index}
            className="ContentRouteValidator__select"
            onChange={e => changeRoute(Number(e.target.value))}
          >
            {ROUTES.map(({ name }, index) => (
              <option key={index} value={index}>
                {name}
              </option>
            ))}
          </select>
          <div className="ContentRouteValidator__help">
            Název trasy najdete na mapě. Pokud ověřujete pomocí QR kódu, trasa
            by měla být předvyplněná.
          </div>
        </div>
        <div className="ContentRouteValidator__number">
          <label htmlFor="number-0" className="ContentRouteValidator__label">
            Kontrolní čísla
          </label>
          {numbers.map((value, index) => (
            <div key={index} className="ContentRouteValidator__numberItem">
              <input
                value={value}
                onChange={e => {
                  changeNumber(e.target.value, index);
                }}
                type="number"
                id={`number-${index}`}
                className="ContentRouteValidator__input"
                step={1}
                max={99}
                min={0}
                required={true}
              />
              <div className="ContentRouteValidator__numberItemTitle">
                {index + 1}.
              </div>
            </div>
          ))}
        </div>
        <button
          disabled={empty}
          type="button"
          className="ContentRouteValidator__button"
          onClick={submit}
        >
          Zkontrolovat
        </button>
        {completed && (
          <div
            className={classNames('ContentRouteValidator__message', {
              'ContentRouteValidator__message--success': success,
              'ContentRouteValidator__message--error': !success,
            })}
          >
            <div className="ContentRouteValidator__messageContent">
              <span>
                {success
                  ? 'Gratulujeme, čísla souhlasí. Trasu jste absolvovali úspěšně.'
                  : 'Je nám líto, ale čísla nesouhlasí. Zkuste to znovu.'}
              </span>
              <button
                type="button"
                className="ContentRouteValidator__button ContentRouteValidator__button--alert"
                onClick={clear}
              >
                {success ? 'Ověřit jiná čísla' : 'Zadat znovu'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ContentRouteValidator;
