import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import classNames from 'classnames';
import Link from 'next/link';
import { useEffect, useRef } from 'react';

import Layout from 'components/Layout';
import { DataCategory, getCategories } from 'data/Category';
import { getPageOrCategory, PageItem, PageItemEventDay } from 'data/Page';
import Hero from 'components/detail/Hero';
import { useRouterHash } from 'hooks/useRouterHash';
import InstagramFeed from 'components/home/InstagramFeed';
import Content from 'components/content/Content';
import PageContent from 'components/detail/PageContent';
import Sidebar from 'components/detail/Sidebar';
import { getEvents } from 'data/Event';

interface CategoryPageProps {
  categories: DataCategory[];
  category: DataCategory;
}

function CategoryPage({ categories, category }: CategoryPageProps) {
  const ref = useRef<HTMLDivElement>(null);
  const hash = useRouterHash();

  useEffect(() => {
    if (!hash || !ref.current) {
      return;
    }

    const element = ref.current.querySelector(`#category-${hash}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [hash]);

  return (
    <Layout
      categories={categories}
      breadcrumb={category.breadcrumb}
      title={category.locale.title}
      localizations={category.localizations}
      image={
        category.image_url ??
        `${process.env.NEXT_PUBLIC_API_URL}/media/page/category/${category.uid}`
      }
    >
      <Hero
        center
        title={category.locale.title}
        image={
          category.image_url ??
          `${process.env.NEXT_PUBLIC_API_URL}/media/page/category/${category.uid}`
        }
      />
      <div className="Container">
        <div className="List" ref={ref}>
          {category.children.map(({ locale, pages }, key) => (
            <div
              key={key}
              className={classNames('List__section', {
                'List__section--highlighted':
                  typeof window !== 'undefined' &&
                  window.location.hash === `#${locale.slug}`,
              })}
              data-title={locale.title}
            >
              <div id={`category-${locale.slug}`} className="List__anchor" />
              <div className="List__sectionContent">
                <Link href={`${category.locale.path}#${locale.slug}`} passHref>
                  <a className="Flag">{locale.title}</a>
                </Link>
                {pages.map(({ locale, uid }) => (
                  <Link key={uid} href={locale.path} passHref>
                    <a className="List__item">
                      <h4>{locale.title}</h4>
                      <p>{locale.subtitle}</p>
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

interface PageProps {
  categories: DataCategory[];
  category: DataCategory | null;
  page: PageItem | null;
  events: PageItemEventDay[];
}

export default function Page({
  categories,
  category,
  page,
  events,
}: PageProps) {
  if (category) {
    return <CategoryPage categories={categories} category={category} />;
  }

  if (!page) {
    return null;
  }

  return (
    <Layout
      categories={categories}
      breadcrumb={page.breadcrumb}
      title={page.locale.title}
      description={page.locale.subtitle}
      image={
        page.image_url ??
        `${process.env.NEXT_PUBLIC_API_URL}/media/page/${page.uid}`
      }
      localizations={page.localizations}
    >
      <Hero
        video={page.video ?? undefined}
        title={<span>{page.locale.title}</span>}
        image={
          page.image_url ??
          `${process.env.NEXT_PUBLIC_API_URL}/media/page/${page.uid}`
        }
      />
      <div className="Container Container--flex Container--animated">
        <Sidebar
          attributes={page.locale.attributes}
          position={page.position ?? undefined}
          travel={page.travel ?? undefined}
          link={page.locale.link_map ?? undefined}
          news={page.locale.news ?? undefined}
        />
        <PageContent
          before={page.before ?? undefined}
          after={page.after ?? undefined}
          attributes={page.locale.other_attributes?.split(';')}
          embed={
            page.locale.slug.includes('via-ferrata') ? (
              <iframe
                src="https://martinstrnad.eu/ferrataStatus.php"
                frameBorder="0"
                scrolling="no"
              />
            ) : undefined
          }
        >
          <Content
            data={page.locale.content}
            agenda={page.use_agenda ? events : undefined}
          />
        </PageContent>
      </div>
      <InstagramFeed />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps<
  PageProps | CategoryPageProps,
  { slug: string }
> = async ({ locale, params, res }) => {
  if (!params?.slug) return { notFound: true };

  const { page, category } = await getPageOrCategory(locale!, params.slug);
  if (!category && !page) return { notFound: true };

  const categories = await getCategories(locale!);

  let events: PageItemEventDay[] = [];
  if (page) {
    events = await getEvents(locale!, params.slug);
    page.locale = { ...page.locale, events: [] };
  }

  res.setHeader('Cache-Control', 'max-age=0, s-maxage=300');
  return {
    props: {
      categories,
      page: page,
      category,
      events,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
};
