import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import classNames from 'classnames';

import { isClientOnMobile } from 'utils/mobile';
import { RecommendItemVideo } from 'data/Recommend';

interface HeroProps {
  title: ReactNode;
  placeholder?: string;
  image?: string;
  center?: boolean;
  light?: boolean;
  video?: RecommendItemVideo;
  time?: number;
}

function Hero({ light, title, video, time, center, image }: HeroProps) {
  const heroRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<YouTube>(null);
  const [fullscreen, setFullscreen] = useState(false);

  const startVideo = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.getInternalPlayer().playVideo();
    // setTimeout(e.target.playVideo, 1000);
  }, [videoRef]);

  const enableFullscreen = useCallback(async () => {
    if (!videoRef.current) {
      return;
    }

    if (fullscreen && document.exitFullscreen) {
      await document.exitFullscreen();
      return;
    }

    setFullscreen(true);
    videoRef.current.getInternalPlayer().unMute();

    await heroRef.current?.requestFullscreen({ navigationUI: 'auto' });
  }, [fullscreen, heroRef]);

  const closeFullscreen = useCallback(async () => {
    if (!document.fullscreenEnabled || !videoRef.current) {
      return;
    }

    await document.exitFullscreen();
    setFullscreen(false);
    videoRef.current.getInternalPlayer().mute();
  }, [videoRef]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', closeFullscreen);
    return () =>
      document.removeEventListener('fullscreenchange', closeFullscreen);
  }, [closeFullscreen]);

  if (light) {
    return (
      <div className="Hero Hero--center Hero--light">
        <div className="Container">
          <h1>{title}</h1>
        </div>
      </div>
    );
  }

  if (video && !isClientOnMobile()) {
    return (
      <div
        ref={heroRef}
        className={classNames('Hero', {
          'Hero--center': center,
          'Hero--fullscreen': fullscreen,
        })}
      >
        <YouTube
          ref={videoRef}
          id={'Hero--Video'}
          className={classNames({ 'Video--fullscreen': fullscreen })}
          videoId={video.identifier}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              showinfo: 0,
              rel: 0,
              loop: 1,
              playlist: video.identifier,
              mute: 1,
              start: time && time > 0 ? time : video.time,
            },
          }}
          onReady={startVideo}
        />
        <div className="Container Container--animated">
          <h1>{title}</h1>
          <div className="Hero__buttonList">
            <button
              onClick={enableFullscreen}
              type={'button'}
              className="Hero__button Hero__button--fullscreen"
            >
              Fullscreen
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('Hero', { 'Hero--center': center })}
      style={{ backgroundImage: image && `url(${image})` }}
    >
      <div className="Container Container--animated">
        <h1>{title}</h1>
      </div>
    </div>
  );
}

export default Hero;
