export interface WeatherResult {
  conditionKey: string;
  condition: string;
  temperature: number;
  icon: string;
}

export async function getWeather(
  latitude: number,
  longitude: number,
): Promise<WeatherResult | null> {
  const path = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&APPID=${process.env.NEXT_PUBLIC_OPEN_WEATHER_MAP_API_KEY}`;
  try {
    const response = await fetch(path);
    const data = (await response.json()) as {
      weather: { main: string; icon: string }[];
      main: {
        temp: number;
      };
    };

    const condition = data.weather[0].main;
    const icon = data.weather[0].icon;
    const temperature = Math.round(data.main.temp);

    return {
      conditionKey: condition.toLowerCase(),
      condition,
      temperature,
      icon,
    };
  } catch (e) {
    return null;
  }
}
