import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import moment from 'moment';

import {
  PageItemAttribute,
  PageItemNews,
  PageItemPosition,
  PageItemTravel,
} from 'data/Page';
import { useWindowSize } from 'hooks/useWindowSize';
import SidebarWeather from 'components/detail/SidebarWeather';
import SidebarTravel from 'components/detail/SidebarTravel';

const DEFAULT_LOCATION: PageItemPosition = {
  longitude: 14.213187,
  latitude: 50.780688,
};

interface SidebarProps {
  attributes: PageItemAttribute[];
  position?: PageItemPosition;
  travel?: PageItemTravel;
  link?: string;
  news?: PageItemNews;
}

function Sidebar({ attributes, position, travel, link, news }: SidebarProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const [height, setHeight] = useState(800);
  const [visible, setVisible] = useState(false);
  const { height: windowHeight } = useWindowSize();

  const unchecked = useMemo(
    () => attributes.filter(a => !a.enabled),
    [attributes],
  );

  const checked = useMemo(
    () => attributes.filter(a => a.enabled),
    [attributes],
  );

  const showNews = useMemo(() => {
    if (!news) {
      return false;
    }

    const date = moment(news.date_published, 'DD. MM. YYYY');
    const compareDate = moment().subtract(1, 'month');
    return date.isAfter(compareDate);
  }, [news]);

  const map = useMemo(() => {
    if (position?.latitude && position?.longitude) {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${position.latitude},${position.longitude}&zoom=12&size=424x208&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`;
    }
    return `https://maps.googleapis.com/maps/api/staticmap?center=${DEFAULT_LOCATION.latitude},${DEFAULT_LOCATION.longitude}&zoom=12&size=424x208&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`;
  }, [position]);

  useEffect(() => {
    if (!ref.current || !windowHeight) {
      return;
    }

    let height = ref.current.clientHeight + 56;
    let maxHeight = windowHeight - 88;

    if (height > maxHeight) {
      height = maxHeight;
    }

    setHeight(height);
    setVisible(true);
  }, [windowHeight, ref]);

  return (
    <div className="Sidebar">
      <div
        className="Sidebar__container"
        style={{
          height: height,
          visibility: visible ? 'visible' : 'hidden',
          opacity: visible ? 1 : 0,
          transform: visible ? 'none' : 'translate3d(15%, 0, 0)',
        }}
      >
        <div className="Sidebar__content">
          <div className="Sidebar__inner" ref={ref}>
            {news && showNews && (
              <Link href={news.path} passHref>
                <a className="Sidebar__news">
                  <small>{news.date_published}</small>
                  <h4>{news.title}</h4>
                  <p>{news.subtitle}</p>
                </a>
              </Link>
            )}
            {position && (
              <SidebarWeather
                latitude={position.latitude}
                longitude={position.longitude}
              />
            )}
            {position && travel && (
              <SidebarTravel position={position} travel={travel} />
            )}
            <div className="Sidebar__attributes Sidebar__attributes--checked">
              {checked.map(({ title }, key) => (
                <div
                  key={key}
                  className="Sidebar__attribute Sidebar__attribute--checked"
                >
                  {title}
                </div>
              ))}
            </div>
            <div className="Sidebar__attributes">
              {unchecked.map(({ title }, key) => (
                <div key={key} className="Sidebar__attribute">
                  {title}
                </div>
              ))}
            </div>
            {link && (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="Sidebar__map"
                style={{ backgroundImage: `url(${map})` }}
              >
                <div className="Sidebar__mapLink">{t('MAP_TITLE')}</div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
